<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-9 col-lg-7 col-xl-6 text-justify">
        <p><strong>GEORGES SIMENON</strong> (Lieja, Bélgica, 1903 – Lausana, Suiza, 1989) escribió ciento noventa y una novelas con su nombre, y un número impreciso de novelas y relatos publicados con pseudónimo, además de libros de memorias y textos dictados. El comisario Maigret es el protagonista de setenta y dos de estas novelas y treinta y un relatos, todos ellos publicados entre 1931 y 1972. Célebre en el mundo entero, reconocido ya como un maestro, hoy nadie duda de que sea uno de los mayores escritores del siglo xx.</p>
        <br>
        <p><strong>Sobre Simenon, se ha dicho:</strong></p>
        <p>«Simenon es un gran escritor, uno de los más importantes escritores de este siglo, un novelista inagotable. Cuanto más lo leo, más me recuerda al mejor Balzac, por esta capacidad de invención inmediata, este conocimiento profundo de los medios más disímiles y opuestos del mundo.»<br>Álvaro Mutis</p>
        <p>«Todo Simenon es bueno.»<br>Martín de Riquer</p>
        <p>«No conozco mejor manera de pasar el tiempo divirtiéndome e instruyéndome en un avión entre Niza y Atenas, Rangún y Singapur, que leer una novela de Simenon.»<br>Somerset Maugham</p>
        <p>«Uno de los escritores más importantes de nuestro siglo.»<br>Gabriel García Márquez</p>
        <p>«La primera vez que leí una novela de Simenon me quedé impresionado por su extraordinaria calidad. Nunca hubiera imaginado que fuera posible escribir algo como aquello, en un estilo tan simple (sólo en apariencia) y directo.»<br>John Banville</p>
        <p>«Creo que Simenon es uno de los mayores escritores del siglo xx, cuyo éxito fue y sigue siendo extraordinario, pero que no fue adecuadamente reconocido. No tuvo el Premio Nobel, a pesar de su evidente superioridad sobre más de la mitad de los que lo han recibido.»<br>Julián Marías</p>
        <p>«Un escritor excepcional, como todo el mundo sabe. En realidad es único y no me refiero sólo a nuestra época, sino a cualquier época.»<br>Henry Miller</p>
        <p>«Hay escritores que admiro muchísimo: habría que hablar cada día del Simenon de los Pitard.» louis-ferdinand céline «El arte de Simenon es de una belleza casi intolerable.»<br>François Mauriac</p>
        <p>«Uno de los grandes escritores del siglo xx.»<br>Roberto Calasso</p>
        <p>«Lo que asombra de Simenon no es que escribiera tantas novelas, sino el hecho de que prácticamente todas sean magníficas y de que además estén dotadas de algo equivalente a una sustancia adictiva, de una poderosa nicotina literaria en virtud de la cual el interés o la admiración del lector se convierten rápidamente en un hábito.»<br>Antonio Muñoz Molina, El País</p>
        <p>«Ni usted ni yo somos neuróticos, pues, cuando nuestras angustias son demasiado grandes, usted escribe un libro y yo hago una película.»<br>Charles Chaplin</p>
        <p>«En la obra de Simenon no hay una novela que sea repetición de otra, ni un personaje igual a otro, ni un ambiente repetido e intercambiable.»<br>Josep Pla</p>
        <p>«La confrontación de Maigret con cualquier novela coetánea, sea o no detectivesca, basta para mostrar su radical originalidad.»<br>Pere Gimferrer</p>
        <p>«Simenon es una de las grandes plumas de la literatura del siglo xx, de la francesa, en lengua francesa, y del mundo entero.»<br>Joan de Sagarra, La Vanguardia</p>
        <p>«La novela policíaca europea (segreguemos Inglaterra al efecto) nace con Georges Simenon, un escritor que rebasa toda clasificación.»<br>José María Guelbenzu, El País</p>
        <p>«Gran Simenon.»<br>Federico Fellini</p>
        <p>«Leo cada nueva novela de Simenon.»<br>Walter Benjamin</p>
        <p>«Considero a Simenon un gran novelista, quizás el más grande y el más auténtico de la literatura francesa contemporánea.»<br>André Gide</p>
        <p>«Adoro leer a Simenon.»<br>William Faulkner</p>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useMeta } from 'vue-meta'
export default {
  name: 'About',
  setup () {
    useMeta({
      title: 'El autor',
      description: 'Georges Simenon escribió ciento noventa y una novelas con su nombre, y un número impreciso de novelas y relatos publicados con pseudónimo, además de libros de memorias y textos dictados.',
      og: {
        title: 'El autor | Georges Simenon',
        url: window.location.href,
        description:
          'Georges Simenon escribió ciento noventa y una novelas con su nombre, y un número impreciso de novelas y relatos publicados con pseudónimo, además de libros de memorias y textos dictados.'
      },
      twitter: {
        title: 'El autor | Georges Simenon',
        description:
          'Georges Simenon escribió ciento noventa y una novelas con su nombre, y un número impreciso de novelas y relatos publicados con pseudónimo, además de libros de memorias y textos dictados.'
      }
    })
    onMounted(() => {
      document.body.style.backgroundColor = '#fab304'
      document.body.style.backgroundImage = 'url("' + require('@/assets/background/autor.gif') + '")'
    })
  }
}
</script>
